import React from 'react';
import { Link } from 'gatsby';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import planoloft from '../../../images/plano-loft.jpg';
import '../../../styles/loft.scss';

const Loft = ({location}) => {

    var settings = {
		dots: true,
		infinite: true,
		accessibility: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	}

    return (
        <Layout currentPath={location}>
			<SEO title="Ínsula Living | Loft" />
            <div className="loft">
				<Link to="/proyectos/insula-living/tipos" className="back">
					&larr;Volver
				</Link>
				<div className="loft__title">
					<div className="loft__texture" />
					<h1>LOFT</h1>
				</div>
				<div className="loft__info-container">
                    <div className="caracteristicas">
                        <div className="caracteristicas__leftbar" />
                        <div className="caracteristicas__title">
                            <p>CARACTERÍSTICAS</p>
                        </div>
                        <div className="caracteristicas__loft">
                            <div className="informacion-loft">
                                <p> Precio: Desde $200'000.000</p>
                                <p> Area construida: 47.89m&#178;</p>
                                <p> Terraza: 16.89m&#178;</p>
                                <p>Loft: 103</p>
                            </div>
                        </div>
                        <div className="caracteristicas__rightbar" />
                    </div>

                    <div className="info-general">
                        <div className="info-general__galeria-loft">
                            <div className="title-modelo">
                                <p>GALERÍA</p>
                            </div>
                            <Slider {...settings} className="sliders-container">
                                <div className="main-slider-container">
                                    <div className="main-slider-container__inner">
                                        
                                    </div>
                                </div>

                                <div className="main-slider-container main-slider-container--2">
                                    <div className="main-slider-container__inner">
                                        
                                    </div>
                                </div>

                                <div className="main-slider-container main-slider-container--3">
                                    <div className="main-slider-container__inner">
                                        
                                    </div>
                                </div>

                                <div className="main-slider-container main-slider-container--4">
                                    <div className="main-slider-container__inner">
                                        
                                    </div>
                                </div>
                            </Slider>
                        </div>

                        <div className="info-general__plano">
                            <div className="title-plano">
                                <p>PLANO</p>
                            </div>
                            <img className="plano-loft" src={planoloft} alt="Plano del loft"/>
                        </div>
                    </div>
				</div>
			</div>
			
		</Layout>
    );
};

export default Loft;